import { IS_ZALO_MINI_APP } from './global'
import React from 'react'
import { ReactComponent as DangKiemXeMoiIcon } from './../assets/Layout2Icons/L2dkxm.svg'
import { ReactComponent as DangKiemXeCuIcon } from './../assets/Layout2Icons/L2dkxc.svg'
import { ReactComponent as TuVanBaoHiemIcon } from './../assets/Layout2Icons/L2tvbh.svg'
import { ReactComponent as TuVanBaoDuongIcon } from './../assets/Layout2Icons/L2tvbd.svg'
import { ReactComponent as TuVanDangKiem } from './../assets/Layout2Icons/L2tvdk.svg'
import { ReactComponent as TuVanHoanCai } from './../assets/Layout2Icons/L2tvhc.svg'
import { ReactComponent as TuDongThongBaoPN } from './../assets/new-icons/TDTBPN.svg'
export const SCHEDULE_TYPE = {
  VEHICLE_INSPECTION: 1, // Đăng kiểm xe định kỳ
  NEW_VEHICLE_INSPECTION: 2, // đăng kiểm xe mới
  // REGISTER_NEW_VEHICLE: 3, // Nộp hồ sơ xe mới
  // INSPECTION_EXTENSION: 99, // Gia hạn đăng kiểm
  CHANGE_REGISTATION: 4, // Đổi mục đích sử dụng, đổi chủ, đổi thông tin hồ sơ
  PAY_ROAD_FEE: 5, // Thanh toán phí đường bộ
  PAY_INSURRANCE_FEE: 6, // Thanh toán phí bảo hiểm
  CONSULTANT_MAINTENANCE: 7, // Đặt lịch tư vấn bảo dưỡng
  CONSULTANT_INSURANCE: 8, // Đặt lịch tư bảo hiểm
  CONSULTANT_RENOVATION: 9, // Đặt lịch tư vấn hoán cải
  AUTOMATED_TRAFFIC_FINE_NOTIFICATION: 10, // Tự động thông báo phạt nguội
}

export const SCHEDULE_TITLE = {
  [SCHEDULE_TYPE.VEHICLE_INSPECTION]: {
    title: 'Old-registration',
    subTitle: 'For-customers1',
    enableStep2:true,
  },
  [SCHEDULE_TYPE.NEW_VEHICLE_INSPECTION]: {
    title: 'New-registration',
    subTitle: 'Dành cho khách hàng đăng kiểm lần đầu - không mang phương tiện đến trạm đăng kiểm',
    enableStep2:true,
  },
  // [SCHEDULE_TYPE.REGISTER_NEW_VEHICLE]: {
  //   title: 'Đăng ký hồ sơ xe mới',
  //   subTitle: 'Dành cho khách hàng đặt lịch nộp hồ sơ xe mới, không mang xe đến đăng kiểm'
  // },
  // [SCHEDULE_TYPE.INSPECTION_EXTENSION]: {
  //   title: 'Gia hạn đăng kiểm',
  //   subTitle: 'Dành cho khách hàng đặt lịch để gia hạn đăng kiểm cho các xe cũ'
  // },
  [SCHEDULE_TYPE.CHANGE_REGISTATION]: {
    title: 'Change-vehicle-information',
    subTitle: 'For-customers2',
    enableStep2:true,
  },
  [SCHEDULE_TYPE.PAY_ROAD_FEE]: {
    title: 'Thanh toán phí đường bộ',
    subTitle: 'Dành cho khách hàng muốn thanh toán phí đường bộ',
    enableStep2:true,
  },
  [SCHEDULE_TYPE.PAY_INSURRANCE_FEE]: {
    title: 'Thanh toán phí bảo hiểm',
    subTitle: 'Dành cho khách hàng muốn thanh toán phí bảo hiểm',
    enableStep2:true,
  },
  [SCHEDULE_TYPE.CONSULTANT_MAINTENANCE]: {
    title: 'CONSULTANT_MAINTENANCE',
    subTitle: 'CONSULTANT_MAINTENANCE_title',
    enableStep2:false,
  },
  [SCHEDULE_TYPE.CONSULTANT_INSURANCE]: {
    title: 'CONSULTANT_INSURANCE',
    subTitle: 'CONSULTANT_INSURANCE_title',
    enableStep2:false,
  },
  [SCHEDULE_TYPE.CONSULTANT_RENOVATION]: {
    title: 'CONSULTANT_RENOVATION',
    subTitle: 'CONSULTANT_RENOVATION_title',
    enableStep2:false,
  },
  [SCHEDULE_TYPE.AUTOMATED_TRAFFIC_FINE_NOTIFICATION]: {
    title: 'AUTOMATED_TRAFFIC_FINE_NOTIFICATION',
    subTitle: 'AUTOMATED_TRAFFIC_FINE_NOTIFICATION_title',
    enableStep2: false,
  }
}

export const SCHEDULE_DATA = [
  {
    id: SCHEDULE_TYPE.VEHICLE_INSPECTION,
    ...SCHEDULE_TITLE[SCHEDULE_TYPE.VEHICLE_INSPECTION],
    icon: <DangKiemXeMoiIcon></DangKiemXeMoiIcon>
  },
  {
    id: SCHEDULE_TYPE.CHANGE_REGISTATION,
    ...SCHEDULE_TITLE[SCHEDULE_TYPE.CHANGE_REGISTATION],
    icon: <DangKiemXeCuIcon></DangKiemXeCuIcon>
  },
  // {
  //   id: SCHEDULE_TYPE.NEW_VEHICLE_INSPECTION,
  //   ...SCHEDULE_TITLE[SCHEDULE_TYPE.NEW_VEHICLE_INSPECTION],
  //   icon: require('../assets/icon3/dk_xe_moi.png')
  // },
  // {
  //   id: SCHEDULE_TYPE.REGISTER_NEW_VEHICLE,
  //   ...SCHEDULE_TITLE[SCHEDULE_TYPE.REGISTER_NEW_VEHICLE],
  //   icon: require('../assets/icon3/dk_ho_so_moi.png')
  // },
  // {
  //   // disabled: true,
  //   onClick: () => {
  //     IS_ZALO_MINI_APP
  //       ? window.open(process.env.REACT_APP_EXTEND_INSPECTION_URL, '_system')
  //       : (window.location.href = process.env.REACT_APP_EXTEND_INSPECTION_URL)
  //   },
  //   id: SCHEDULE_TYPE.INSPECTION_EXTENSION,
  //   ...SCHEDULE_TITLE[SCHEDULE_TYPE.INSPECTION_EXTENSION],
  //   icon: require('../assets/icon3/gia_han_dk.png')
  // }
]

export const SCHEDULE_ASKADVICE = [
  {
    id: SCHEDULE_TYPE.CONSULTANT_MAINTENANCE,
    ...SCHEDULE_TITLE[SCHEDULE_TYPE.CONSULTANT_MAINTENANCE],
    icon: <TuVanBaoDuongIcon></TuVanBaoDuongIcon>,
    path:'/askadvice-maintenance',
  },
  {
    id: SCHEDULE_TYPE.CONSULTANT_INSURANCE,
    ...SCHEDULE_TITLE[SCHEDULE_TYPE.CONSULTANT_INSURANCE],
    icon: <TuVanBaoHiemIcon></TuVanBaoHiemIcon>,
    path:'/askadvice-insurrance',
  },
  {
    id: SCHEDULE_TYPE.CONSULTANT_RENOVATION,
    ...SCHEDULE_TITLE[SCHEDULE_TYPE.CONSULTANT_RENOVATION],
    icon: <TuVanHoanCai></TuVanHoanCai>,
    path:'/askadvice-upgrade-vehicle',
  },
  {
    id: SCHEDULE_TYPE.AUTOMATED_TRAFFIC_FINE_NOTIFICATION,
    ...SCHEDULE_TITLE[SCHEDULE_TYPE.AUTOMATED_TRAFFIC_FINE_NOTIFICATION],
    icon: <TuDongThongBaoPN></TuDongThongBaoPN>,
    path:'/automated-traffic-fine-notification/services',
    usePath: true
  },
]