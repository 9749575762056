import React, { useEffect, useRef, useState } from 'react'
import { BANNER_LAYOUT } from 'constants/banner';
import { BANNER_TYPE } from 'constants/banner';
import { useSelector } from 'react-redux';
import "./index.scss"
import { Button, Carousel, Drawer, Modal, Space } from 'antd';
import { getBannerBySectionCache } from 'helper/getBannerBySectionCache';
import { ClickCountableArea } from 'components/BasicComponent/ClickCountableArea';
import { TARGET_ID } from 'constants/targetList';
export default function AdsBanner({
    setpopupStep
}) {
    const currentPath = window.location.pathname;

    const [bannersAds, setBanner] = useState([])
    useEffect(() => {
            getBannerBySectionCache(BANNER_TYPE[BANNER_LAYOUT.ADS_BANNER]).then(data =>{
                setBanner(data)
            })
    }, []);
    // KH đã vào app chưa!
    const isVisited = localStorage.getItem('visited');
    const [open, setOpen] = useState(false);

    // Kiểm tra xem banner đã được hiển thị trong ngày chưa
    function handleCheckAdsBanner() {
        const storedVisitCount = parseInt(localStorage.getItem('storedVisitCount') || 0);
        const randomCount = parseInt(localStorage.getItem('randomVisitCount') || 0)   
        const reviewed = localStorage.getItem('reviewed');
        // Lấy ngày hiện tại
        const currentDate = new Date().toDateString();
        
        // Kiểm tra xem có thông tin về việc hiển thị banner cho ngày hiện tại trong localstore không
        const lastDisplayDate = localStorage.getItem('lastDisplayAdsDate');
        //Lưu thông tin đã vào app
        if(!isVisited){
            localStorage.setItem('visited', true);
        }
         //Nếu popup đánh giá hiện thì popup quảng cáo không hiện
        else if(!reviewed && storedVisitCount  >= randomCount) {
            setOpen(false);
            setpopupStep(4)
        }
        // Nếu KH đã vào app, không có thông tin hoặc thông tin đã lưu không phải ngày hiện tại và ở tran chủ => hiển thị banner
        else if (isVisited && (!lastDisplayDate || (lastDisplayDate !== currentDate)) && currentPath === "/") {
            // Hiển thị banner
            setOpen(true);
            // Lưu thông tin về việc hiển thị banner cho ngày hiện tại vào localstore
            localStorage.setItem('lastDisplayAdsDate', currentDate);
        }else{
            setpopupStep(4)
        }     
}
    useEffect(() => {
        handleCheckAdsBanner()
    }, []);


    const onClose = () => {
        setOpen(false);
        setpopupStep(4)
    };
    return (
        <>
            {
                bannersAds?.length ? <Modal
                    onCancel={onClose}
                    placement="top"
                    wrapClassName='ads-modal'
                    width={400}
                    height={400}
                    footer={null}
                    open={open}
                >
                    {
                        bannersAds?.length ? (<div className='wrap-ads-banner'>
                            <div className='ads-banner'>
                                <ClickCountableArea Id={TARGET_ID.ADSBANNER.id}  className='custom-slide' link={bannersAds[0]?.bannerUrl || "#"} target={bannersAds[0]?.bannerUrl ? "_blank" : "_self"}>
                                    <img src={bannersAds[0]?.bannerImageUrl} alt={bannersAds[0]?.bannerName} />
                                </ClickCountableArea>
                            </div>
                        </div>) : <></>
                    }
                </Modal> : <></>
            }
        </>


    )
}
