import React, { useEffect, useMemo, useState } from 'react'
import { BANNER_LAYOUT } from 'constants/banner';
import { BANNER_TYPE } from 'constants/banner';
import { AspectRatio } from 'react-aspect-ratio'
import Slider from 'react-slick'
import { useSelector } from 'react-redux';
import "./index.scss"
import { useLocation, useRouteMatch } from 'react-router-dom';
import { getBannerBySectionCache } from 'helper/getBannerBySectionCache';
import { ClickCountableArea } from 'components/BasicComponent/ClickCountableArea';
import { TARGET_ID } from 'constants/targetList';
const settingSilde = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};
export default function BannerFooter({
    isSchedule=false,
    className=""
}) {
    const layoutApp = process.env.REACT_APP_LAYOUT
    const location = useLocation();
    const [banners, setBanner] = useState([])
    const isBooking = location?.pathname === "/booking"
    useEffect(() => {
        if (isSchedule) {
            getBannerBySectionCache(BANNER_TYPE[BANNER_LAYOUT.SCHEDULED_DETAIL]).then(data =>{
                setBanner(data)
            })
        }
        else if (location?.pathname === "/") {
            getBannerBySectionCache(BANNER_TYPE[BANNER_LAYOUT.HOME_BOTTOM]).then(data =>{
                setBanner(data)
            })
        }
    }, [location,isSchedule]);

    return (
        <div >
            <div>
                {banners?.length ?
                    <div className={`${className}  ${layoutApp == 2 && 'homeLayout2'} bottom-banner ${isBooking ? 'bottom-banner-schedule' : ''}`}style={{ overflow: 'hidden' }}>
                        <ClickCountableArea Id={TARGET_ID.FOOTERBANNER.id}  className='custom-slide' link={banners[0]?.bannerUrl || "#"} target={banners[0]?.bannerUrl ? "_blank" : "_self"}>
                            <img src={banners[0].bannerImageUrl} alt={banners[0]?.bannerName} />
                        </ClickCountableArea>
                    </div>
                    : <></>
                }
            </div>
        </div>
    )
}
